import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Link } from "gatsby";

export default function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="">
      <div className="px-4 pt-4 pb-2 mx-auto border-t border-gray-700 max-w-7xl lg:pt-8 lg:px-8">
        <p className="text-base text-center text-gray-400">
          &copy; {new Date().getFullYear()}. {t(`footer-copyright`)}
        </p>
      </div>
      <div className="px-4 pb-4 mx-auto max-w-7xl lg:pb-8 lg:px-8">
        <p className="flex flex-col items-center justify-center text-base text-center text-gray-400 md:flex-row">
          <Link
            to="/politika-privatnosti"
            target="_blank"
            rel="noopener noreferrer"
            className="mb-1 md:mb-0 md:mr-1"
          >
            {t(`footer-privacy`)}
          </Link>{" "}
          <span className="hidden mx-2 md:inline">|</span>
          <Link
            to="/uslovi-koriscenja"
            target="_blank"
            rel="noopener noreferrer"
            className="mb-1 md:mb-0 md:mr-1"
          >
            {t(`footer-terms`)}
          </Link>{" "}
          <span className="hidden mx-2 md:inline">|</span>
          <Link
            to="/politika-kolacica"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t(`footer-cookies`)}
          </Link>
        </p>
      </div>
    </footer>
  );
}
